import { atcb_action } from 'add-to-calendar-button';

const config = {
    name: "Kristina og Per sitt bryllup",
    description: "Kristina og Per gifter seg i en kirke, et sted. Ta med smil, og dress / kjole?",
    startDate: "2023-09-30",
    endDate: "2023-09-30",
    options: [
        "Apple",
        "Google",
        "Outlook.com",
    ],
    location: "Dalbakkveien 39, 0682 Oslo",
    timeZone: "Europe/Berlin",
    trigger: "click",
    iCalFileName: "Reminder-Event",
};

const addToCalendar = (event) => {
    atcb_action(config, event.target);
}

export default addToCalendar;