import Layout from "./Layout";
import submitRSVPForm from "../helpers/RSVPHelper";
import { FormEvent } from "react";

const RSVP = () => {
    let actuallyComing: boolean;// = false;

    const handleSubmit = (event: FormEvent) => {
        event.preventDefault();
        if (actuallyComing === undefined || actuallyComing === null) {
            alert('Du må velge om du kommer eller ikke');
            return;
        }

        const form = (document.getElementById('rsvp_form') as HTMLFormElement);

        const name = (document.getElementById('rsvp_form_name') as HTMLInputElement).value;
        const allergies = (document.getElementById('rsvp_form_allergies') as HTMLInputElement).value;
        //const coming = (document.getElementById('rsvp_form_coming') as HTMLInputElement).checked;
        // const password = (document.getElementById('rsvp_form_password') as HTMLInputElement).value;

        submitRSVPForm(form, {
            name: name,
            allergies: allergies,
            coming: actuallyComing
        });

        document.getElementById('rsvp_form_coming_yes')?.classList.remove('active');
        document.getElementById('rsvp_form_coming_no')?.classList.remove('active');
    };

    const handleComing = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, coming: boolean) => {
        event.preventDefault();
        const antiBtn = document.getElementById(`rsvp_form_coming_${coming ? 'no' : 'yes'}`);
        const btn = event.target as HTMLButtonElement;
        antiBtn?.classList.remove('active');
        btn.classList.add('active');

        actuallyComing = coming;
    };

    const content = (
        <div className="content_container">
            <p>Om du er invitert, vil vi gjerne høre om du kan komme eller ikke.</p>
            <p>Send inn én besvarelse per person hvis dere er flere som er invitert!</p>
            <p>Skriv ditt fulle navn i boksen, om du kan komme eller ikke, og eventuelt allergener om du har det.</p>
            <p>Hvis du trenger å oppdatere noe, bare skriv det samme navnet du skrev inn først og send inn på nytt.</p>
            <p>Du kan også svare ved å <a href="mailto:perniklaslongberg@gmail.com">sende oss en epost</a></p>
            <form id="rsvp_form" onSubmit={(event) => handleSubmit(event)} autoComplete="off">
                <div className="rsvp_form_row">
                    <label htmlFor="rsvp_form_name">Navn</label>
                    <input type="text" id="rsvp_form_name" autoComplete="off" />
                </div>
                <div className="rsvp_form_row">
                    <label htmlFor="rsvp_form_allergies">Allergener</label>
                    <input type="text" id="rsvp_form_allergies" autoComplete="off" />
                </div>
                {/* <div className="rsvp_form_row">
                    <label htmlFor="rsvp_form_coming">Passord</label>
                    <input type="text" id="rsvp_form_password" autoComplete="off"/>
                </div> */}
                <div className="rsvp_form_row">
                    <label htmlFor="rsvp_form_coming">Kommer du?</label>
                    <div style={{ display: "flex", flexDirection: 'row', width: '100%', justifyContent: "space-evenly" }}>
                        <button className="coming" id="rsvp_form_coming_no" onClick={(e) => handleComing(e, false)}>Nei</button>
                        <button className="coming" id="rsvp_form_coming_yes" onClick={(e) => handleComing(e, true)}>Ja</button>
                    </div>
                    {/* <input type="checkbox" defaultChecked id="rsvp_form_coming" autoComplete="off"/> */}
                </div>
                {/* <div className="rsvp_form_row">
                    <label htmlFor="name">Middag</label>
                    <select name="dinners" id="rsvp_form_dinners">
                        <option value="meat">Kjøtt</option>
                        <option value="fish">Fisk</option>
                        <option value="vegetarian">Vegetar</option>
                    </select>
                </div> */}
                <button type="submit" id="rsvp_form_submit">Send</button>
            </form>
        </div>
    );

    return (
        <Layout header="Påmelding eller avmelding" content={content} id="rsvp"></Layout>
    )
};

export default RSVP;