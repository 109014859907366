import Layout from "./Layout";
import addToCalendar from '../helpers/addToCalendar';

const Home = () => {
    const content = (
        <div className="content_container light">
            <div className="content_container_container">
                <p>Hallaien! Vi er to rabalderbasser som liker å henge sammen, litt som Romeo og Juliet. 
                    Bare at familiene våre ikke hater hverandre, og at det ikke er noe problem at vi er sammen.
                    Og at ingen av oss drikker gift på slutten av dagen. Forhåpentligvis.</p>
                <p>Selv om vi har hengt sammen på fulltid i flere år, har vi nå bestemt oss for å henge sammen enda mer!</p>
                <div>
                    <p>Sett av datoen! (trykk for å legge til i kalenderen din)</p>
                    <a id="date" onClick={(event:any) => addToCalendar(event)} title="Lagre i kalender'n din"> 30.09.2023</a>
                </div>
            </div>
        </div>
    );

    return (
        <Layout header="Vi gifter oss! Det er kult." content={content} id="home" imageDark={true}></Layout>
    );
}

export default Home;