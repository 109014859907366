import { initializeApp } from "firebase/app";
import {
    getAuth,
    signInWithEmailAndPassword
} from "firebase/auth";
import {
    getFirestore
} from "firebase/firestore";

const firebaseConfig = {
    apiKey: "AIzaSyB56TanBCXNzFZL-9t0BkDyPLfhh5Lqxuk",
    authDomain: "bryllup-421ad.firebaseapp.com",
    projectId: "bryllup-421ad",
    storageBucket: "bryllup-421ad.appspot.com",
    messagingSenderId: "1061304233595",
    appId: "1:1061304233595:web:e1b7a3848707c5b7483cde"
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);

export default app;