import { addDoc, collection, deleteDoc, doc, getDocs, query, setDoc } from 'firebase/firestore';
import { db } from './Firebase';

export async function getAttendees() {
    const people = [];
    const rsvpQuery = query(collection(db, "rsvp"));
    const rsvpDocs = await getDocs(rsvpQuery);
    rsvpDocs.forEach((snapshot) => {
        var personData = snapshot.data();
        console.log(personData);
        people.push(personData);
    });

    return people;
}

export async function getWishlist() {
    const items = [];
    const wishlistQuery = query(collection(db, "wishlist"));
    const withlistDocs = await getDocs(wishlistQuery);
    withlistDocs.forEach((snapshot) => {
        var itemData = snapshot.data();
        itemData.id = snapshot.id;
        items.push(itemData);
    });

    return items;
};

export async function addWishlistItem(item) {
    if (!item) {
        return;
    }

    let doc = await addDoc(collection(db, 'wishlist'), item);
    return doc.id ?? 'hehe';
};

export async function removeWishlistItem(id) {
    const docRef = doc(db, 'wishlist', id);
    await deleteDoc(docRef);
};