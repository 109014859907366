import { useEffect, useState } from "react";
import { getWishlist } from "../helpers/adminHelper";
import Layout from "./Layout";

interface WishlistItem {
    name: string;
    id: string;
    link?: string;
};

const Wishlist = () => {
    const emptyWish: WishlistItem[] = [];
    const [wishlist, setWishlist] = useState(emptyWish);

    useEffect(() => {
        if (!wishlist.length) {
            getWishlist().then((response: WishlistItem[]) => {
                setWishlist(response);
            });
        }
    }, []);

    let itemIndex = 0;
    const content = (
        <div className="content_container">
            <p>
                Selv om det er veldig hyggelig med gaver,
                så er det <b>helt frivillig</b>. Vi setter størst pris på om dere vil feire med oss!
            </p>
            <div className="content_splitter"></div>
            <p>
                Bryllupsreisen går til Japan, og vi ønsker oss derfor <span id="a_i_norsk">økonomisk</span> bistand til turen :) 
                Om du vil bistå har vi opprettet en konto med kontonr 1202.40.91404, men du kan også vippse.
            </p>
            <p>
                Vi har plutselig oppdaget at vi også mangler diverse kjøkkenutstyr, så vi satt sammen en liste på <a href="https://www.kitchn.no/bryllup/vis-liste/166097" target="_blank">Kitch'n</a>.
            </p>
            <p>
                Ellers trenger vi bestikk, sengetøy, håndklær til badet, kjøkkenkluter og lignende.
            </p>
            {/*
            <table className="wishlist_table">
                <thead>
                    <tr>
                        <th className="table_header">Ønskeliste</th>
                    </tr>
                </thead>
                <tbody className="wishlist_table_content">
                    {wishlist.map((item: WishlistItem) => {
                        itemIndex++;
                        let content = (<span>{item.name}</span>);
                        if (item.link) {
                            content = (
                                <a href={item.link}>{item.name}</a>
                            );
                        }

                        return (
                            <tr key={itemIndex}>
                                <td className="item_name">{content}</td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>
                */}
        </div>
    );

    return (
        <Layout header="Vi ønsker oss ikke stavmikser" content={content} id="wishlist"></Layout>
    )
};

export default Wishlist;