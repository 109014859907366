import Layout from "./Layout";

const PracticalInfo = () => {
    const content = (
        <div className="content_container">
            <div className="content_textonly">
                <div className="content_text">
                    <h2>Toastmaster</h2>
                    <p>
                        Vi er heldige, så vi har fått Thomas Longberg som toastmaster til bryllupet.
                    </p>
                    <p>
                        Hvis du ønsker å holde en tale, ta gjerne kontakt med han på <a href="mailto:thmslngbrd@gmail.com">thmslngbrd@gmail.com</a>.
                    </p>
                    <p>
                        Hvis du ønsker å bli bedre kjent med Thomas, så spør han om sykkelturen han tok for å komme seg til bryllupet!
                    </p>
                </div>
            </div>
            <div className="content_splitter"></div>
            <div className="content_left">
                <div className="content_text">
                    <h2>Vielsen</h2>
                    <p>
                        Selve vielsen starter 13:00, og vil finne sted i Søndre Skøyen Kapell
                    </p>
                    <p>
                        Om du ikke kan veien, kan du bruke kartet sin veibeskrivelse eller sjekke ut kirkens informasjon <a href="https://www.kirken.no/nb-NO/fellesrad/kirkeneioslo/menigheter/oppsal/om-oss/veibeskrivelse-til-ssk/">her.</a> På linken finner du også informasjon om parkering.
                    </p>
                </div>
                <div className="mapouter">
                    <div className="gmap_canvas">
                        <iframe id="gmap_chapel"
                            src="https://maps.google.com/maps?q=Dalbakkveien%2039,%200682%20Oslo&t=&z=15&ie=UTF8&iwloc=&output=embed"
                            frameBorder="0" scrolling="no" marginHeight={0} marginWidth={0}>
                        </iframe>
                    </div>
                </div>
            </div>
            <div className="content_splitter"></div>
            <div className="content_right">
                <div className="content_text">
                    <h2>Middagen</h2>
                    <p>
                        Etter vielsen rusler vi bort til <b>Nordre Skøyen Hovedgård</b> for å spise god mat og feire. Hovedgården ligger knappe 350 meter unna kapellet.
                    </p>
                    <p>
                        Her spiser vi, holder taler, mingler og generelt koser oss. Maten vil bli servert fra ca 16:00, og det står en treretters på menyen.
                    </p>
                </div>
                <div className="mapouter">
                    <div className="gmap_canvas">
                        <iframe id="gmap_canvas"
                            src="https://maps.google.com/maps?q=Peter%20Aas'%20vei%2017,%200667%20Oslo&t=&z=15&ie=UTF8&iwloc=&output=embed"
                            frameBorder={0} scrolling="no" marginHeight={0} marginWidth={0}>
                        </iframe>
                    </div>
                </div>
            </div>
            <div className="content_splitter"></div>
            <div className="content_textonly">
                <div className="content_text">
                    <h2>Festen</h2>
                    <p>
                        For dere som kun er invitert til festen, så er det jo egentlig dagens høydepunkt. Vi har satset på at den starter 19:27 ish, og den vil være i samme lokale som middagen.<br />
                        Vi serverer drikke både med og uten alkohol, kaker, kaffe og snacks. Om du ønsker å ta med egen alkohol er det også lov.
                    </p>
                </div>
            </div>
        </div>
    );

    return (
        <Layout header="Praktisk info" content={content} id="info"></Layout>
    )
};

export default PracticalInfo;