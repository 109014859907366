// Get the imports
import { initializeApp } from 'firebase/app';
import { getFunctions, httpsCallable } from "firebase/functions";
import { getFirestore, doc, setDoc, addDoc, getDoc, collection } from 'firebase/firestore';
import app from './Firebase';

// Init the firebase app
// const firebaseConfig = {
//     apiKey: "AIzaSyB56TanBCXNzFZL-9t0BkDyPLfhh5Lqxuk",
//     authDomain: "bryllup-421ad.firebaseapp.com",
//     projectId: "bryllup-421ad",
//     storageBucket: "bryllup-421ad.appspot.com",
//     messagingSenderId: "1061304233595",
//     appId: "1:1061304233595:web:e1b7a3848707c5b7483cde"
// };

// const app = initializeApp(firebaseConfig);
const firestore = getFirestore();

const submitRSVPForm = async (form, data) => {
    if (!data.name) {
        alert("Du må ha et navn, dessverre");
        return;
    }
    const colref = collection(firestore, "rsvp");
    console.log(data);

    const docco = doc(colref, data.name);
    setDoc(docco, {
        name: data.name,
        allergies: data.allergies,
        coming: data.coming
    });

    alert(`Svar for ${data.name} er levert!`);
    form.reset();
    
    // const sendRSVP = httpsCallable(functions, 'sendRSVP');
    // sendRSVP({
    //     name: data.name,
    //     allergies: data.allergies,
    //     coming: data.coming,
    //     password: data.password
    // }).then((result) => {
    //     console.log(result);
    //     alert(`Svar for ${data.name} er levert!`);
    //     form.reset();
    // }).error((error) => {
    //     console.log(error);
    //     alert("Noe gikk galt!");
    // });
}

export default submitRSVPForm;