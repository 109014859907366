import './styles/App.css';
import './styles/navbar.css';
import './styles/content.css';
import './styles/Home.css';
import './styles/Maps.css';
import './styles/PracticalInfo.css';
import './styles/Wishlist.css';
import './styles/RSVP.css';
import './styles/Admin.css';
import './styles/Rolling.css';

import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link
} from "react-router-dom";

import Home from './components/Home';
import Wishlist from './components/Wishlist';
import PracticalInfo from './components/PracticalInfo';
import RSVP from './components/RSVP';
import Admin from './components/Admin';
import enableHeader from './helpers/Header';

function delay(time:number) {
  return new Promise(resolve => setTimeout(resolve, time));
}

function App() {
  const activateNavLink = (element:any) => {
    const btns = document.getElementsByClassName("navigation_bar_item");
    for (let i = 0; i < btns.length; i++) {
      btns.item(i)?.classList.remove("active");
    }

    element.classList.add("active");
  };

  setTimeout(() => {
    let route = (window.location.href ?? "").split('/').at(-1);
    if (!route?.length) route = 'home';
    console.log(route);
    document.getElementById(`navigationbutton_${route}`)?.classList.add('active');
  }, 150);

  const recursiveCheck = async (attempts:number) => {
    let elements = document.querySelectorAll('.rolling-text');
    if (elements.length === 0 && attempts < 10) {
      await delay(500);
      recursiveCheck(attempts + 1);
      return;
    } else {
      enableHeader();
    }
  };

  recursiveCheck(0);

  return (
    <Router>
      <div>
        <nav id="navigation_bar">
          {/* <h1 id="navigation_bar_header">KRISTINA &amp; PER</h1> */}
          <Link className="rolling-text" id="header" to="/home" onClick={() => { activateNavLink(document.getElementsByClassName("navigation_bar_item").item(0))}}>
            PER &amp; KRISTINA
          </Link>
          <div id="navigation_bar_items">
            <Link id="navigationbutton_home" className="navigation_bar_item" to="/home" onClick={(e:any) => activateNavLink(e.target)}>Hjem</Link>
            <Link id="navigationbutton_practical" className="navigation_bar_item" to="/practical" onClick={(e:any) => activateNavLink(e.target)}>Info</Link>
            <Link id="navigationbutton_wishlist" className="navigation_bar_item" to="/wishlist" onClick={(e:any) => activateNavLink(e.target)}>Ønskeliste</Link>
            <Link id="navigationbutton_rsvp" className="navigation_bar_item" to="/rsvp" onClick={(e:any) => activateNavLink(e.target)}>RSVP</Link>
          </div>
        </nav>

        {/* A <Switch> looks through its children <Route>s and
            renders the first one that matches the current URL. */}
        <Routes>
          <Route path="/" element={<Home />}></Route>
          <Route path="/home" element={<Home />}></Route>
          <Route path="/practical" element={<PracticalInfo />}></Route>
          <Route path="/wishlist" element={<Wishlist />}></Route>
          <Route path="/rsvp" element={<RSVP />}></Route>
          <Route path="/admin" element={<Admin />}></Route>
          {/* <Route path="/" */}
        </Routes>
      </div>
    </Router>
  );
}

export default App;
