import { signInWithEmailAndPassword } from 'firebase/auth';
import { MouseEvent, useEffect, useState } from 'react';
import { addWishlistItem, getAttendees, getWishlist, removeWishlistItem } from '../helpers/adminHelper';
import { auth } from '../helpers/Firebase';

interface Person {
    allergies: string;
    coming: boolean;
    name: string;
}

const Login = () => {
    const handleLogin = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        console.log(event);
        const username = document.getElementById('login_username') as HTMLInputElement;
        const password = document.getElementById('login_password') as HTMLInputElement;

        try {
            signInWithEmailAndPassword(auth, username.value, password.value);
        } catch (e) {
            alert(e);
        }
    };

    return (
        <div id="login">
            <form onSubmit={handleLogin}>
                <input id="login_username" type="text" autoComplete='username' placeholder='email'></input>
                <input id="login_password" type="password" autoComplete='current-password' placeholder='password'></input>
                <button type="submit">Logg inn</button>
            </form>
        </div>
    );
};

interface WishlistItem {
    name: string;
    id: string;
    link?: string;
};

interface Person {
    name: string;
    allergies: string;
    coming: boolean;
}

const Admin = () => {
    const [loggedIn, setLoggedIn] = useState(false);
    const emptyRsvp: Person[] = [];
    const emptyWishlist: WishlistItem[] = [];
    const [rsvp, setRsvp] = useState(emptyRsvp);
    const [wishlist, setWishlist] = useState((emptyWishlist));
    const [allergies, setAllergies] = useState([] as String[]);

    auth.onAuthStateChanged((user) => {
        if (user) {
            setLoggedIn(true);
        } else {
            setLoggedIn(false);
        }
    });

    useEffect(() => {
        if (!loggedIn)
            return;

        if (!rsvp.length) {
            getAttendees().then((response: Person[]) => {
                setRsvp(response);
                
                const all: String[] = [];
                response.forEach(p => {
                    if (p.allergies && !all.includes(p.allergies.toLowerCase())) {
                        all.push(p.allergies.toLowerCase());
                    }
                    setAllergies(all);
                });
            });
        }

        if (!wishlist.length) {
            getWishlist().then((response: WishlistItem[]) => {
                setWishlist(response);
            });
        }
    }, [loggedIn, rsvp, wishlist]);

    if (loggedIn) {
        let personIndex = 0;
        return (
            <div id="admin" className="content_container">
                <div id="admin_result">
                    <table id="admin_result_table">
                        <thead>
                            <tr>
                                <th className="table_header">Navn</th>
                                <th className="table_header" id="coming_header">Kommer?</th>
                                <th className="table_header">Allergener</th>
                            </tr>
                        </thead>
                        <tbody id="admin_result_table_rows">
                            {rsvp.map((person: Person) => {
                                personIndex++;
                                return (
                                    <tr key={personIndex}>
                                        <td>{person.name}</td>
                                        <td><input type="checkbox" disabled checked={person.coming} /></td>
                                        <td>{person.allergies}</td>
                                    </tr>
                                );
                            })}
                        </tbody>
                        <tfoot>
                            <tr>
                                <td></td>
                                <td style={{textAlign: 'center'}}>{rsvp.filter(r => r.coming).length}</td>
                                <td>{allergies.join(', ')}</td>
                            </tr>
                        </tfoot>
                    </table>
                </div>
            </div>
        )
    } else {
        return Login();
    }
};

export default Admin;