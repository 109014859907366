interface Props {
    header: string;
    content: any;
    id?: string;
    imageDark?: boolean
}

const Layout = (props:Props) => {
    return (
        <div className="content" id={props.id}>
            <div className={"content_header" + (props.imageDark ? " light" : "")}>
                <h1>{props.header}</h1>
            </div>
            {/* <div className={"content_container" + (props.imageDark ? " light" : "")}>{props.content}</div> */}
            {props.content}
        </div>
    );
}

export default Layout;